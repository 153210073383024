<script lang="ts">
    import { getContext } from 'svelte';
    import { inertia } from '@inertiajs/svelte';
    import UserIcon from '../../Icons/UserIcon.svelte';
    import SettingsIcon from '../../Icons/SettingIcon.svelte';
    import LinkIcon from '../../Icons/LinkIcon.svelte';
    import StackIcon from '../../Icons/StackIcon.svelte';
    import UsersIcon from '../../Icons/UsersIcon.svelte';
    import UserPlus from '../../Icons/UserPlus.svelte';
    import NotificationIcon from '../../Icons/NotificationIcon.svelte';
    import CheckIcon from '../../Icons/CheckIcon.svelte';
    import ShieldIcon from '../../Icons/ShieldIcon.svelte';
    import ForwardChevron from '../../Icons/ForwardChevron.svelte';
    import DoubleCheck from '../../Icons/DoubleCheck.svelte';

    interface SettingsContext {
        availableSettings: Record<string, { icon: string }>;
        activeSettingsTab: string;
        data: { max_verification: number };
        authUser: any;
        authUserVerification: {
            status: string;
            verification_level: number;
        } | null;
    }

    const settingsContext = getContext<SettingsContext>('settingsContext');
    let { availableSettings, activeSettingsTab, data, authUser, authUserVerification } = settingsContext;

    const iconComponents: Record<string, any> = {
        UserIcons: UserIcon,
        settings: SettingsIcon,
        link: LinkIcon,
        layers: StackIcon,
        people: UsersIcon,
        'person-add': UserPlus,
        notifications: NotificationIcon,
        shield: ShieldIcon,
        checkmark: CheckIcon,
        'checkmark-done': DoubleCheck,
    };

    function handleClick(key: string) {
        activeSettingsTab = key;
    }
</script>

<div class="hidden lg:block" id="settings-nav">
    <div class="border-b border-neutral-200 dark:border-neutral-800">
        <div class="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-800">
            {#each Object.entries(availableSettings) as [key, setting]}
                {#if (key == 'vip-verification' && authUserVerification?.status == 'verified' && authUserVerification?.verification_level == data.max_verification) || key != 'vip-verification'}
                    <a
                        href="/my/settings/{key}"
                        use:inertia
                        class="flex items-center justify-between px-4 py-3 transition-colors duration-200 ease-in-out
                               hover:bg-neutral-100 dark:hover:bg-neutral-900
                               {activeSettingsTab === key ? 'bg-neutral-100 dark:bg-neutral-900' : ''}"
                        on:click="{() => handleClick(key)}"
                    >
                        <div class="flex items-center">
                            <svelte:component
                                this="{iconComponents[setting.icon]}"
                                type="{activeSettingsTab === key ? 'solid' : ''}"
                                class="h-5 w-5"
                            />
                            <span class="ml-3 capitalize">{key}</span>
                        </div>
                        <ForwardChevron class="h-5 w-5" />
                    </a>
                {/if}
            {/each}
        </div>
    </div>
</div>

<style>
    /* Add any custom styles here if needed */
</style>
